function Header({ isPremium }) {
    return (
        <header>
            <h1>Transcripció automàtica en català</h1>
            { !isPremium && <p id="warning-desktop" role="alert">Perquè l'aplicació funcioni correctament, cal utilitzar un ordinador amb el navegador Google Chrome.</p> }
            { isPremium &&  <p id="warning-desktop" role="alert">La versió professional pot ser utilitzada també en dispositius mòbils.</p> }
        </header>
    );
}

export default Header;