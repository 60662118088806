import './PremiumPopup.css';

function PremiumPopup({
  isAuthenticated,
  setShowPremiumPopup,
  loginWithRedirect,
  redirectToStripe,
}) {
  const styles = {
    popupContainer: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: '1000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto', // Added to allow scrolling
    },
    popupContent: {
      position: 'relative',
      width: '70%',
      maxWidth: '800px',
      maxHeight: '90vh', // Max height to ensure it doesn't exceed the viewport height
      overflowY: 'auto', // Allows scrolling within the popup content if it exceeds maxHeight
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      padding: '40px',
      zIndex: '1001',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    closeBtn: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      color: '#333',
      background: 'transparent',
      border: 'none',
      fontSize: '1.5rem',
      cursor: 'pointer',
    },
    header: {
      fontSize: '2rem',
      fontWeight: '600',
      color: '#333',
      marginBottom: '20px',
    },
    featureList: {
      alignSelf: 'stretch',
      listStyle: 'none',
      padding: '0 0 10px 20px',
    },
    featureItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      color: '#333',
      fontSize: '1rem',
      fontWeight: '400',
    },
    checkIcon: {
      color: '#4BB543',
      marginRight: '10px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#b95fdd',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
      margin: '0 auto',
    },
    price: {
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '20px',
    },
  };

  const closePopup = (e) => {
    e.stopPropagation();
    setShowPremiumPopup(false);
  };

  const loginInAuth0 = () => {
    loginWithRedirect({
        appState: { returnTo: '/showPopup' },
        screen_hint: 'signup',
        language: 'ca',
    });
  }

  return (
    <div style={styles.popupContainer} onClick={closePopup}>
      <div style={styles.popupContent} onClick={(e) => e.stopPropagation()}>
        <button style={styles.closeBtn} onClick={closePopup}>
          &times;
        </button>
        <h1 style={styles.header}>Versió professional</h1>

        <div className="wrap-if-mobile" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: '1', maxWidth: '100%' }}>
                <video style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} loop autoPlay muted playsInline>
                  <source src="/assets/demo_premium.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div style={{ 
                    marginTop: '12px',
                    textAlign: 'center',
                    color: '#666',
                    fontSize: '0.8rem',
                    fontStyle: 'italic'
                }}>
                    El teu temps és or. Vídeo a velocitat real
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: '1', maxWidth: '100%', padding: 20, justifyContent: 'center' }}>
                { !isAuthenticated ? (
                    <button style={styles.button} onClick={loginInAuth0}>
                        Inicia sessió per accedir-hi
                    </button>
                    ) : (
                    <button style={styles.button} onClick={redirectToStripe}>
                        La vull! (24,99€)
                    </button>
                )}

                <ul style={{ listStyle: 'none', padding: 0 }}>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <span style={{ marginRight: '10px' }}>✔</span>
                    Transcriu 1 hora en 1 minut
                    </li>
                    {/* <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <span style={{ marginRight: '10px' }}>✔</span>
                    Identifica diferents veus
                    </li> */}
                    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <span style={{ marginRight: '10px' }}>✔</span>
                    Qualitat de transcripció més alta
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <span style={{ marginRight: '10px' }}>✔</span>
                    Només 24,99€ una sola vegada
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
}

export default PremiumPopup;